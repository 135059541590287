export const TERMS = {
  12: '1 Jahr',
  24: '2 Jahre',
  36: '3 Jahre',
  48: '4 Jahre',
  60: '5 Jahre',
  72: '6 Jahre',
  84: '7 Jahre',
  96: '8 Jahre',
  108: '9 Jahre',
  120: '10 Jahre',
};

export const REAL_ESTATE_VALUE = 'real_estate_financing';

export enum PURPOSES {
  refinancing = 'Umschuldung',
  pre_owned_car = 'Gebrauchtfahrzeug',
  new_car = 'Neufahrzeug',
  furniture = 'Einrichtung/Möbel',
  renovation = 'Renovierung',
  holiday = 'Urlaub',
  entertainment_electronics = 'PC/TV/Hifi/Video',
  account_balance = 'Ausgleich Girokonto',
  move = 'Umzug',
  real_estate_financing = 'Immobilienfinanzierung',
  other = 'Freie Verwendung',
}

export const DEFAULT_AMOUNT = 30000;
export const DEFAULT_AMOUNT_HOME = 20000;
export const DEFAULT_MORTGAGE_AMOUNT = 300000;

export const DEFAULT_TERM = 84;
export const DEFAULT_PURPOSE = 'other';

export const AMOUNT_MAX = 5000000;
export const AMOUNT_MIN = 1000;
export const AMOUNT_MAX_NON_MORTGAGE = 150000;
export const AMOUNT_MAX_SMALL_MORTGAGE = 50000;
export const AMOUNT_MAX_SMALL_HOME_RELATED = 120000;

export enum LABEL {
  Purpose = 'Verwendungszweck',
  Amount = 'Wunschbetrag',
  Term = 'Laufzeit',
}

export const MIN_AMOUNT_ERROR =
  'Der Mindestbetrag muss mindestens 1.000 € sein';

export const MAX_AMOUNT_ERROR =
  'Der Höchstbetrag darf maximal 5.000.000 € sein';

export const LOAN_TYPES = {
  car: 'car_loan',
  installment: 'installment_loan',
};
